<template>
    <div class="layout">
        <div v-if="hdbtEvent">
            <inline-svg class="logo" :src="require('@/assets/hdbt_logo.svg')"></inline-svg>
            <div class="event">
                <p>Welcome to</p>
                <h1>{{hdbtEvent.name}}</h1>
                <p class="event__description">Starring Pro AV’s Largest</p>
                <p class="event__description">Interoperable Ecosystem</p>
            </div>
            <img class="event__logo" alt="Event logo" :src="hdbtEvent.logoUrl" />
            <button class="noBg">
                <inline-svg :src="require('@/assets/question.svg')"></inline-svg>
                <span @click="howToPlayOpen = true">How to play</span>
            </button>
        </div>
        <div v-if="hdbtEvent" class="footer">
            <div class="buttons_wrapper">
                <button @click="$router.push({name: 'register'})">
                    Create account to play
                </button>
                <span>OR</span>
                <GoogleLogin :buttonConfig="googleButtonConfig" :callback="callback"/>
            </div>
            <p class="">Already have an account? <router-link to="/login">Login here</router-link></p>
        </div>
    </div>
    <HowToPlayView :open="howToPlayOpen" :showFoot="false" @close="howToPlayOpen = false" ></HowToPlayView>
</template>
<script>
import HowToPlayView from "./HowToPlayView.vue";
import firebaseService from "../../services/firebase.service";
import analyticsService from '../../services/analytics.service';
import { decodeCredential } from 'vue3-google-login'
import authService from "../../services/auth.service";

export default {
    name: 'HomeView',
    components: {
        HowToPlayView
    },
    data() {
        return {
            hdbtEvent: null,
            howToPlayOpen: false,
            googleButtonConfig: {
                size: 'large',
                text: 'Sign up with Google',
                width: '300',
                theme: 'filled_blue'
            }
        }
    },
    methods: {
        callback(response) {
            const userData = decodeCredential(response.credential);

            const userEmail = userData.email;
            const userPass = userData.sub;
            const userName = userData.given_name;
            const userLName = userData.family_name;

            const emailDomain = userEmail.split('@')[1];
            let userCompany;

            if(emailDomain !== 'gmail.com') {
                userCompany = emailDomain.split('.')[0];
            } else {
                userCompany = '-';
            }

            const userJobTitle = '-';

            // Try to sign in with the user's email and password
            firebaseService.login(userEmail, userPass)
                .then((res) => {
                    if (res.authToken) {
                        authService.setAuth(res.authToken);
                        firebaseService.getUser();
                        analyticsService.sendEvent('login_view_success', {email: this.email});
                        if (authService.getPlayURL()) {
                            this.$router.push({ path: authService.getPlayURL() });
                        } else {
                            this.$router.push({ name: 'home' });
                        }
                    }
                })
                .catch(() => {
                    // If sign in fails, create a new user with the user's email and password
                    firebaseService.register(
                        userEmail,
                        userPass,
                        userName,
                        userLName,
                        userCompany,
                        userJobTitle
                    ).then((response) => {
                        if (response.authToken) {
                            authService.setAuth(response.authToken);
                            firebaseService.getUser();
                            analyticsService.sendEvent('register_succeess', {
                                email: userEmail, firstName: userName, lastName: userLName,
                                company: userCompany, jobTitle: userJobTitle});
                            if (authService.getPlayURL()) {
                                this.$router.push({ path: authService.getPlayURL() });
                            } else {
                                this.$router.push({ name: 'home' });
                            }
                        }
                    });
                });
        }
    },
    created() {
        analyticsService.sendEvent('no_auth_view', {});
        firebaseService.getEvent()
            .then( hdbtEvent => {
                this.hdbtEvent = hdbtEvent;
            }).catch( error => {
                console.error(error);
            });
    }
}

</script>
<style scoped lang="scss">

.layout--game.open {
    top: 0vh !important;
}

.layout {
    color: var(--color-font-light);
    background-image: url(../../assets/hdbaset-bg-home.jpg);
    background-position: center;
    background-size: cover;
    & > div {
        text-align: center;
        align-items: center;
    }
}

.logo {
    width: 220px;
    height: 53px;
    margin-top: 50px
}

.event {
    & > * {
        margin: 0px 0px 10px 0px;
    }
    & > *:last-child {
        margin-bottom: 0px;
    }
    p {
        font-size: 20px;
    }
}
.event__logo {
    height: 120px;
    border-radius: 2px;
}

.event__description {
    margin-bottom: 0px;
}

.footer {
    a {
        color: var(--color-font-light);
    }

    .buttons_wrapper {
        display: flex;
        align-items: center;

        // mobile styling
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        span {
            margin: 0px 30px;

            @media (max-width: 768px) {
                margin: 20px 0;
            }
        }
    }
}

</style>