<template>
  <router-view></router-view>
</template>

<script>
import '../public/css/normalize.css';
import '../public/css/styles.scss';

export default {
  name: 'App',
  mounted() {
    // Adding scripts to the head, available globally
    const scripts = [
      "https://apis.google.com/js/api:client.js",
    ];
    
    scripts.forEach(script => {
      let tag = document.head.querySelector(`[src="${ script }"`);
      if (!tag) {
        tag = document.createElement("script");
        tag.setAttribute("src", script);
        tag.setAttribute("type", 'text/javascript');
        document.head.appendChild(tag); 
      }
    });
  }
}
</script>

<style>
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

</style>
