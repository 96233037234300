<template>
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :defaultColDef="defaultColDef"
      :columnTypes="columnTypes"
      @grid-ready="onGridReady"
      @first-data-rendered="onFirstDataRendered"
    >
    </ag-grid-vue>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { ColumnTypes } from "../../../ag-grid.utils.js";
import Constants from "../../../constants";
import EditPartnerButtonView from "./EditPartnerButtonView.vue";
import DeletePartnerButtonView from "./DeletePartnerButtonView.vue";
import QrCodeGoogleLinkView from "./QrCodeGoogleLinkView.vue";
import GameLinkView from "./GameLinkView.vue";

export default {
  name: "PartnersView",
  components: {
    AgGridVue,
    editPartnerRenderer: EditPartnerButtonView,
    deletePartnerRenderer: DeletePartnerButtonView,
    qrCodeRenderer: QrCodeGoogleLinkView,
    gameLinkRenderer: GameLinkView
  },
  beforeMount() {
    this.columnDefs = [
      { headerName: "Logo", field: "logoUrl", type: "imageColumn" },
      { headerName: "Name", field: "name"},
      { headerName: "Description", field: "description" },
      { headerName: "Location", field: "booth" },
      { 
        headerName: "Game link",
        field: "gameLink",
        cellRendererFramework: "gameLinkRenderer",
      },
      { 
        headerName: "QR",
        field: "qrLink",
        cellRendererFramework: "qrCodeRenderer",
      },
      {
        headerName: "Edit",
        field: "editCallback",
        cellRendererFramework: "editPartnerRenderer",
      },
      {
        headerName: "Delete",
        field: "deleteCallback",
        cellRendererFramework: "deletePartnerRenderer",
      },
    ];
  },
  props: {
    partners: Array,
    formHandler: Function,
    deleteHandler: Function
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      defaultColDef: { resizable: true, sortable: false, filter: true },
      columnTypes: ColumnTypes,
      gridApi: null,
      gridColumnApi: null
    };
  },
  watch: {
    partners: function (values) {
      this.reloadPartners(values);
    },
  },
  created() {
    this.reloadPartners(this.partners);
  },
  methods: {
    reloadPartners(data) {
      if (null == data || 1 > data.length) {
        this.rowData = [];
        return;
      }
      this.rowData = data.map((value) => {
        const playLink = `${Constants.VUE_APP_HDBT_HOST}/play/${encodeURIComponent(value.id)}`;
        return Object.assign(value, {
          gameLink: playLink,
          qrLink: playLink,
          editCallback: () => {
            this.formHandler(this.partners.find( p => p.id === value.id));
          },
          deleteCallback: () => {
            this.deleteHandler(this.partners.find( p => p.id === value.id));
          },
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import '../../../../public/css/admin.scss';
.partners {
  height: 100%;
  width: 100%;
}
</style>