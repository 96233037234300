<template>
    <div class="container">
        <div class="menu">
            <div class="tab" :class="{'selected': TABS.MEMBERS === tabIndex }" @click="selectOption(TABS.MEMBERS)">
                <inline-svg :src="require('@/assets/members.svg')"></inline-svg>
                <span>Members</span>
            </div>
            <div  class="tab" :class="{'selected': TABS.PRIZES === tabIndex }"  @click="selectOption(TABS.PRIZES)">
                <inline-svg :src="require('@/assets/prizes.svg')"></inline-svg>
                <span>Prizes</span>
            </div>
        </div>
        <div id="members-prices-container" :class="{ 'open' : open}">
            <div class="header">
                <div>
                    <span v-if="TABS.MEMBERS === tabIndex">Members</span>
                    <span v-if="TABS.PRIZES === tabIndex">Prizes</span>
                </div>
                <inline-svg class="logo" :src="require('@/assets/x-close.svg')" @click="selectOption(null)" ></inline-svg>
            </div>
            <div class="content"> 
                <div v-if="TABS.MEMBERS === tabIndex">
                    <img src="../../assets/members-directory.png" alt="How to play image" ref="membersTopElement"/>
                    <div class="instructions">
                        <h1>Members Directory</h1>
                        <p>The following is our complete list of members participating in the HDBaseT Uncompressed Adventure.</p>
                        
                        <div class="member" v-for="member of members" :key="member.id">
                            <div class="member__image" :class="{'member__image--played': member.played}">
                                <span class="member__badge" v-if="member.played">Played</span>
                                <img :src="member.logoUrl" alt="Member logo" />
                            </div>
                            <div class="member__name">{{member.name}}</div>
                            <div class="member__description">{{member.description}}</div>
                            <div class="member__location">
                                <span class="label">
                                    <inline-svg :src="require('@/assets/location.svg')"></inline-svg>
                                    <span>Location</span>
                                </span>
                                <span>{{member.booth}}</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-if="TABS.PRIZES === tabIndex" class="prizes">
                    <img :src="hdbtEvent.prizesUrl" alt="Prizes" ref="prizesTopElement" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import firebaseService from "../../services/firebase.service";
import analyticsService from '../../services/analytics.service';

export default {
    name: 'MembersPrizesView',
    data() {
        return {
            open: false,
            tabIndex: null,
            members: null,
            hdbtEvent: null
        }
    },
    created() {
        this.TABS = {
            MEMBERS: 0,
            PRIZES: 1
        };
        this.initPartnersList();
    },
    methods: {
        async initPartnersList() {
            this.hdbtEvent = await firebaseService.getEvent();
            const user = await firebaseService.getUser();
            if (this.hdbtEvent && this.hdbtEvent.partners) {
                this.members = this.hdbtEvent.partners.map( partner => {
                    partner['played'] = user.playedGames.some(g => g.partnerId === partner.id);
                    return partner;
                })
            }
        },
        selectOption(tab) {
            if (null != tab) {
                this.tabIndex = tab;
                this.open = true;
                if (this.$refs['prizesTopElement']) {
                    this.$refs['prizesTopElement'].scrollIntoView({behavior: 'smooth', block: 'start'});
                }
                if (this.$refs['membersTopElement']) {
                    this.$refs['membersTopElement'].scrollIntoView({behavior: 'smooth', block: 'start'});
                }
                analyticsService.sendEvent('members_prizes_view_close', { option: this.TABS.MEMBERS === this.tabIndex ? 'members' : 'prizes'});
            } else {
                this.open = false;
                this.tabIndex = null;
                analyticsService.sendEvent('members_prizes_view_close', {});
            }
        }
    }
}
</script>
<style scoped lang="scss">

.container #members-prices-container {
    position: fixed;
    z-index: 1;
    transition: top 0.2s;
    background: #ffffff;
    top: 100%;
    &.open {
       top: 0%; 
       height: calc(100% - 64px);
    }
    .content {
        height: calc(100% - 64px);
        overflow-y: auto;
        img {
            width: 100%;
            object-fit: cover;
        }
        p {
            font-size: 18px;
            margin: 0px;
            line-height: 1.33;
            text-align: left;
        }
    }
    .header {
        background-color: var(--color-background-dark);
        font-family: var(--font-title);
        font-size: 20px;
        font-weight: bold;
        display:flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 25px 0px 20px;
        color: var(--color-font-light);
        height: 64px;
        width: 100vw;
        box-sizing: border-box;
    }
}

.instructions {
    padding: 24px 20px 48px 20px;
}

h1 {
    font-family: var(--font-title);
    color: var(--color-background-dark);
    font-size: 27px;
    font-weight: 800;
    margin: 0px 0px 10px 0px
}

.menu {
    display: flex;
    color: var(--color-font-light);
    background-color: var(--color-background-dark);
    position: relative;
    z-index: 1;
    svg {
        margin-right: 6px;
    }
    & > div {
        width: 50%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.tab.selected {
    background-color: var(--color-background-selected);
}

.content .member {
    margin-top: 24px;
    &:not(:last-child) {
        padding-bottom: 24px;
        border-bottom: 1px solid #a4a7ab;
    }
    .member__image {
        position: relative;
        box-sizing: border-box;
        height: 126px;
        margin-bottom: 20px;
        img {
            height: 126px;
            border-radius: 2px;
            object-fit: cover;
        }
    }
    .member__image--played {
        border-top: 4px solid #00aeef;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }
    .member__badge {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 2px 8px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        background-color: #00aeef;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        color: var(--color-font-light);
    }
    .member__name {
        font-family: var(--font-title);
        font-size: 20px;
        font-weight: 800;
        color: var(--color-background-dark);
        margin-bottom: 10px;
    }
    .member__description {
        font-size: 18px;
        margin-bottom: 20px;
    }
    .member__location {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        color: var(--color-background-dark);
        .label {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: bold;
            margin-bottom: 10px;
            svg {
                margin-right: 6px;
            }
        }
    }
}

.container .content .prizes {
    width: 100vw;
    height: calc(100% - 128px);
    display: flex;
    align-items: start;
    img {
        height: unset;
        width: 100%;
    }
}
</style>