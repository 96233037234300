<template>
    <div class="admin">
        <header>
            <nav>
                <ul class="admin__menu">
                    <li :class="{ selected: TABS.EVENT === tabIndex }" @click="tabIndex = TABS.EVENT">
                        Event
                    </li>
                    <li v-if="null != hdbtEvent" :class="{ selected: TABS.GAMES === tabIndex }" @click="tabIndex = TABS.GAMES">
                        Played games
                    </li>
                    <li v-if="null != hdbtEvent" :class="{ selected: TABS.PARTNERS === tabIndex }" @click="tabIndex = TABS.PARTNERS">
                        Members
                    </li>
                    <li v-if="null != hdbtEvent" :class="{ selected: TABS.REGISTERED === tabIndex }" @click="tabIndex = TABS.REGISTERED">
                        Registered
                    </li>
                </ul>
            </nav>
            <inline-svg class="logo" :src="require('@/assets/hdbt_logo.svg')"></inline-svg>
            <button v-if="TABS.EVENT === tabIndex" @click="hdbtEventForm.show = true">{{null != hdbtEvent ? 'Edit event' : 'Create event'}}</button>
            <button v-if="null != hdbtEvent && (TABS.GAMES === tabIndex || TABS.REGISTERED === tabIndex)"              @click="csvClickHandler">
                CSV
                <a ref="cvsAnchor" :href="playedGamesCsv" :download="csvName"></a>
            </button>
            <button v-if="null != hdbtEvent && TABS.PARTNERS === tabIndex" @click="openPartnerFrom(null)">New member</button>
        </header>
        <main>
            <HdbtEventView v-if="TABS.EVENT === tabIndex" :hdbtEvent="hdbtEvent"></HdbtEventView>
            <PlayedGamesView v-if="TABS.GAMES === tabIndex" @csv="playedGamesCsvHandler($event)"></PlayedGamesView>
            <PartnersView v-if="TABS.PARTNERS === tabIndex" :formHandler="openPartnerFrom" :deleteHandler="deletePartnerHandler" :partners="hdbtEvent ? hdbtEvent.partners : []"></PartnersView>
            <RegisteredView v-if="TABS.REGISTERED === tabIndex" @csv="playedGamesCsvHandler($event)"></RegisteredView>
        </main>
        <PartnerFormView :visible="partnerForm.show" :partner="partnerForm.selected" @close="partnerFormCloseHandler"></PartnerFormView>
        <HdbtEventFormView :visible="hdbtEventForm.show" :hdbtEvent=hdbtEvent @close="hdbtEventFormCloseHandler"></HdbtEventFormView>
        <ConfirmView :params="confirmParams" @close="closeConfirmHandler" ></ConfirmView>
    </div>
</template>

<script>
import firebaseService from "../../../services/firebase.service";

import PlayedGamesView from './PlayedGamesView.vue';
import PartnersView from './PartnersView.vue';
import PartnerFormView from "./PartnerFormView.vue";
import HdbtEventFormView from  "./HdbtEventFormView.vue";
import HdbtEventView from "./HdbtEventView.vue";
import ConfirmView from "../shared/ConfirmView.vue";
import RegisteredView from "./RegisteredView.vue";

export default {
    name: "AdminView",
    created() {
        this.TABS = {
            GAMES: 0,
            PARTNERS: 1,
            EVENT: 2,
            REGISTERED: 3
        };
        firebaseService.getEvent()
            .then( hdbtEvent => {
                this.hdbtEvent = hdbtEvent;
                this.tabIndex = this.TABS.GAMES;
            }).catch( error => {
                console.error(error);
                this.tabIndex = this.TABS.EVENT;
                this.hdbtEventForm.show = true;
            });
    },
    data() {
        return {
            tabIndex: null,
            hdbtEvent: null,
            partnerForm: {
                show: false,
                selected: null
            },
            hdbtEventForm: {
                show: false
            },
            confirmParams: null,
            playedGamesCsv: null,
            csvName: 'playedGames.csv'
        }
    },
    components: {
        PlayedGamesView,
        RegisteredView,
        PartnersView,
        PartnerFormView,
        HdbtEventFormView,
        HdbtEventView,
        ConfirmView
    },
    methods: {
        openPartnerFrom(selectedPartner) {
            this.partnerForm.selected = selectedPartner;
            this.partnerForm.show = true;
        },
        deletePartnerHandler(selectedPartner) {
            this.confirmParams = {
                visible: true,
                title: 'Delete member?',
                description: `Are you sure you want to delete "${selectedPartner.name}"?                
                You can’t undo this action.`,
                confirmHandler: () => this.deletePartner(selectedPartner)
            }
        },
        async deletePartner(partner) {
            await firebaseService.deletePartner(partner.id);
            const partners = this.hdbtEvent.partners.filter(p => p.id !== partner.id);
            this.hdbtEvent.partners = partners;
            this.closeConfirmHandler();
        },
        partnerFormCloseHandler(data) {
            if (data) {
                const partner = this.hdbtEvent.partners.find(p => p.id === data.id);
                if (partner) {
                    if (data.logoUrl) {
                       data.logoUrl += `?${(new Date()).getTime()}`;
                    }
                    Object.assign(partner, data);
                    const temp = [... this.hdbtEvent.partners];
                    temp.sort( (a, b) => a.name.trim().toLocaleUpperCase().localeCompare(b.name.trim(), undefined, {numeric: true, sensitivity: 'base'}));
                    this.hdbtEvent.partners = temp;
                } else {
                    const temp = [data, ...this.hdbtEvent.partners];
                    temp.sort( (a, b) => a.name.trim().toLocaleUpperCase().localeCompare(b.name.trim(), undefined, {numeric: true, sensitivity: 'base'}));
                    this.hdbtEvent.partners = temp;
                }
            }
            this.partnerForm.show = false;
        },
        hdbtEventFormCloseHandler(data) {
            if (null == this.hdbtEvent) {
                this.hdbtEvent = {};
            }
            Object.assign(this.hdbtEvent, data);
            if (null == this.hdbtEvent.partners) {
                this.hdbtEvent.partners = [];
            }
            this.hdbtEventForm.show = false;
        },
        closeConfirmHandler() {
            this.confirmParams = {};
        },
        playedGamesCsvHandler(eventData) {
            this.playedGamesCsv = eventData.csv;
            this.csvName = eventData.name; 
        },
        csvClickHandler() {
            if (this.playedGamesCsv) {
                this.$refs.cvsAnchor.click();
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import '../../../../public/css/admin.scss';
</style>